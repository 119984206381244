import loadable from '@loadable/component';

const landingRoutes = {
  project: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'project'
  },
  projects: {
    component: loadable(() => import(/* webpackChunkName: "projects" */ './section-projects')),
    mods: 'pageFill pageSearch',
    pagesAccept: true,
  },
};

export default landingRoutes;
