import { landingRoute, i18n, slashCheck } from 'helpers/router';

import loadable from '@loadable/component';
import landingRoutes from './landing-routes';

export default function () {
  return i18n({
    path: '/',
    component: loadable(() => import(/* webpackChunkName: "body" */ './body')),
    indexRoute: {
      component: loadable(() => import(/* webpackChunkName: "home" */ './section-home')),
      mods: 'pageFill'
    },
    childRoutes: [
      {
        path: 'about',
        onEnter: slashCheck,
        mods: 'pageFill',
        component: loadable(() => import(/* webpackChunkName: "about" */ './section-about'))
      },
      {
        path: 'how-we-calculate-score',
        onEnter: slashCheck,
        mods: 'pageFill',
        component: loadable(() => import(/* webpackChunkName: "how-we-calculate-score" */ './section-how-we-calculate-score'))
      },
      {
        path: 'contacts',
        onEnter: slashCheck,
        mods: 'pageFill',
        component: loadable(() => import(/* webpackChunkName: "contacts" */ './section-contacts'))
      },
      {
        path: 'projects',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "projects" */ './section-projects'))
      },
      {
        path: 'project',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "project" */ './section-project'))
      },
      /*
      {
        path: 'informers',
        getComponent: asyncComponent(() => import('./section-informers'))
      },
      {
        path: 'order',
        getComponent: asyncComponent(() => import('./section-order'))
      },
      {
        path: 'subscribe',
        getComponent: asyncComponent(() => import('./section-subscribe'))
      },
      */
      // *** Пользовательское соглашение
      {
        path: 'soglashenie',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "soglashenie" */ './section-terms'))
      },
      // *** Политика обработки персональных данных
      {
        path: 'confidence',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "confidence" */ './section-registration-condition'))
      },
      // ------------ Favorites -----------------
      {
        path: 'auth/favorites/:project',
        mods: 'pageFill',
        component: loadable(() => import(/* webpackChunkName: "favorites" */ './subsys/favorites'))
      },
      // *** Landings
      {
        path: '*',
        getComponent: landingRoute(landingRoutes)
      }
    ]
  });
}
